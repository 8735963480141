import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { connectAuthEmulator, getAuth, provideAuth } from "@angular/fire/auth";
import { connectFirestoreEmulator, getFirestore, provideFirestore } from "@angular/fire/firestore";
import { connectFunctionsEmulator, getFunctions, provideFunctions } from "@angular/fire/functions";
import { connectStorageEmulator, getStorage, provideStorage } from "@angular/fire/storage";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter, Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "../environments/environment";
import { routes } from "./app.routes";
import { AuthService } from "./auth/auth.service";

registerLocaleData(localeNl);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideAnimations(),
    {provide: LOCALE_ID, useValue: "nl-NL"},
    {
      provide:    APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authService.init(),
      deps:       [AuthService],
      multi:      true
    },
    {
      provide:  ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    }, {
      provide: Sentry.TraceService,
      deps:    [Router]
    },
    {
      provide:    APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps:       [Sentry.TraceService],
      multi:      true
    },
    provideAnimationsAsync(),
    importProvidersFrom([
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => {
        const auth = getAuth();
        if (!environment.production) {
          connectAuthEmulator(auth, "http://localhost:9099", {disableWarnings: true});
        }
        return auth;
      }),
      provideFirestore(() => {
        const firestore = getFirestore();
        if (!environment.production) {
          connectFirestoreEmulator(firestore, "localhost", 8080);
        }
        return firestore;
      }),
      provideFunctions(() => {
        const functions = getFunctions(undefined, "europe-west1");
        if (!environment.production) {
          connectFunctionsEmulator(functions, "localhost", 5001);
        }
        return functions;
      }),
      provideStorage(() => {
        const storage = getStorage();
        if (!environment.production) {
          connectStorageEmulator(storage, "localhost", 9199);
        }
        return storage;
      }),
      provideAnalytics(() => getAnalytics())
    ])
  ]
}
