import { computed, inject, Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc
} from "@angular/fire/firestore";
import { catchError, map, Observable, throwError } from "rxjs";
import {
  ConfigNotificationUser,
  ConfigNotificationUsers,
  ConfigRate,
  CoxhoeveUser,
  LogTracker,
  notificationTypes
} from "../models";
import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: "root"
})
export class ConfigService {

  firestore: Firestore   = inject(Firestore);
  logger: LoggingService = inject(LoggingService);

  readonly configured: WritableSignal<boolean>                            = signal(true);
  readonly notificationUsers: Signal<ConfigNotificationUsers | undefined> = toSignal(this.getNotificationUsers());

  constructor() {
    this.checkIfConfigured();
  }

  getGuestbookPrompt() {
    const ref = doc(this.firestore, "config", "guestbookPrompt");
    return docData(ref);
  }

  setGuestbookPrompt(prompt: string) {
    const ref = doc(this.firestore, "config", "guestbookPrompt");
    return setDoc(ref, {prompt});
  }

  /**
   * Retrieves the notification user for a specific type
   * @param type
   */
  public selectNotificationUser(type: notificationTypes): Signal<ConfigNotificationUser | undefined> {
    return computed(() => this.notificationUsers()?.[type]);
  }

  /**
   * Sets the notification user for a specific type
   * @param type The type of notification
   * @param user The user to set as notification user
   */
  public async setNotificationUser(type: string, user: CoxhoeveUser) {
    this.logger.log(user);
    const u   = {
      name: user.displayName,
      id:   user.uid
    };
    const ref = doc(this.firestore, "config", "notificationUsers");
    return setDoc(ref, {[type]: u}, {merge: true});
  }

  /**
   * Retrieves the rate configurations from the database
   */
  public getRateConfigs(): Signal<ConfigRate[]> {
    const ref = collection(this.firestore, "config", "rateConfig", "rates");
    const q   = query(ref, orderBy("name", "asc"));
    return toSignal(collectionData(q, {idField: "id"}) as Observable<ConfigRate[]>, {initialValue: []});
  }

  /**
   * Sets the rate configuration in the database
   * @param rate
   */
  public async setRateConfig(rate: ConfigRate) {
    let ref;
    if (rate.id) {
      ref = doc(this.firestore, "config", "rateConfig", "rates", rate.id);
      return setDoc(ref, rate, {merge: true});
    } else {
      ref = collection(this.firestore, "config", "rateConfig", "rates");
      return addDoc(ref, rate);
    }
  }

  /**
   * Deletes the rate configuration from the database
   * @param id
   */
  public deleteRateConfig(id: string) {
    const ref = doc(this.firestore, "config", "rateConfig", "rates", id);
    return deleteDoc(ref).catch(err => {
      this.logger.error(err);
      throw new Error("internal-error");
    });
  }

  /**
   * Updates the access property for a specific rate
   * @param rate
   * @param module
   * @param value
   */
  public setRateAccessConfig(rate: ConfigRate, module: keyof ConfigRate["access"], value: boolean) {
    // Use Firestore API to update the specific access property for the given rate
    const ref = doc(this.firestore, "config", "rateConfig", "rates", rate.id!);
    return updateDoc(ref, {[`access.${module}`]: value});
  }

  /**
   * Retrieves the rate configuration from the database
   * @param id
   */
  public getRateConfig(id: string) {
    const ref = doc(this.firestore, "config", "rateConfig", "rates", id);
    return docData(ref) as Observable<ConfigRate>;
  }

  /**
   * Retrieves the manual categories from the database
   * @returns An observable of the manual categories
   */
  public getManualCategories(): Observable<string[]> {
    const docRef = doc(this.firestore, "config", "manualCategories");
    return docData(docRef).pipe(
      map(data => data ? data["categories"] : [])
    );
  }

  /**
   * Overwrites the manual categories in the database
   * @param categories
   */
  public setManualCategories(categories: string[]) {
    categories   = categories.map(category => category.toLowerCase().replace(/ /g, "-"));
    const docRef = doc(this.firestore, "config", "manualCategories");
    return setDoc(docRef, {categories});
  }

  /**
   * Retrieves the manual categories from the database
   */
  public getChoresCategories(): Observable<string[]> {
    const docRef = doc(this.firestore, "config", "choresCategories");
    return docData(docRef).pipe(
      map(data => data ? data["categories"] : []),
      catchError(err => {
        this.logger.error(err);
        return throwError(() => "Het ophalen van de categorieën is mislukt");
      })
    );
  }

  /**
   * Overwrites the chores categories in the database
   * @param categories
   */
  public setChoresCategories(categories: string[]): Promise<void> {
    categories   = categories.map(category => category.toLowerCase().replace(/ /g, "-"));
    const docRef = doc(this.firestore, "config", "choresCategories");
    return setDoc(docRef, {categories}).catch(err => {
      this.logger.error(err);
      throw new Error("Het opslaan van de categorieën is mislukt");
    });
  }

  /**
   * Retrieves the high season dates from the database
   */
  getHighSeasonDates(): Observable<{ start: string, end: string }> {
    const ref = doc(this.firestore, "config", "highSeasonDates");
    return docData(ref).pipe(
      map((data: any) => {
        const res: any = {};
        if (data && "start") {
          res.start = data.start;
        }
        if (data && "end") {
          res.end = data.end;
        }
        return res;
      }),
      catchError(err => {
        this.logger.error(err);  // Log the error (assuming you have a logger service).
        return throwError(() => new Error("Het ophalen van de categorieën is mislukt"));
      })
    ) as Observable<{
      start: string,
      end: string
    }>;
  }

  getFormattedHighSeasonDates(): Observable<{ start: Date, end: Date }> {
    return this.getHighSeasonDates().pipe(
      map(dates => {
        const currentYear = new Date().getFullYear();

        const [startMonth, startDay] = dates.start.split("-").map(Number);
        const start                  = new Date(currentYear, startMonth - 1, startDay);  // Months are 0-indexed

        const [endMonth, endDay] = dates.end.split("-").map(Number);
        const end                = new Date(currentYear, endMonth - 1, endDay);

        return {
          start,
          end
        };
      }),
      catchError(err => {
        this.logger.error(err);
        return throwError(() => "Het ophalen van de categorieën is mislukt");
      })
    ) as Observable<{
      start: Date,
      end: Date
    }>;
  }

  setHighSeasonDates(dates: { start?: string, end?: string }): Promise<any> {
    const ref = doc(this.firestore, "config", "highSeasonDates");
    if (dates.start) {
      return setDoc(ref, {start: dates.start}, {merge: true});
    }
    if (dates.end) {
      return setDoc(ref, {end: dates.end}, {merge: true});
    }

    return Promise.reject("Geen start- of einddatum gevonden");

  }

  /**
   * When a new consumption log is submitted, the meter readings are stored in the config document
   * This is used to set the consumption for the next log
   */
  public async getLogTracker(): Promise<LogTracker> {
    try {
      const docRef  = doc(this.firestore, "config", "logTracker");
      const docSnap = await getDoc(docRef);
      return docSnap.data() as LogTracker;

    } catch (e) {
      this.logger.error(e);
      throw Error("Het ophalen van de meterstanden is mislukt");
    }
  }

  /**
   * Retrieves the notification users from the database
   */
  private getNotificationUsers(): Observable<ConfigNotificationUsers> {
    const ref = doc(this.firestore, "config", "notificationUsers");
    return docData(ref) as Observable<ConfigNotificationUsers>;
  }

  /**
   * Checks if all config documents are present in the database
   * @private
   */
  private async checkIfConfigured() {

    let allConfigured: boolean;
    const configDocs: string[] = [
      "choresCategories",
      "highSeasonDates",
      "manualCategories",
      "notificationUsers"
    ];
    let docSnaps: any[]        = [];

    try {
      const docPromises = configDocs.map(docName => getDoc(doc(this.firestore, "config", docName)));
      docSnaps          = await Promise.all(docPromises);
    } catch (e) {
      this.logger.error(e);
    }

    try {
      const rateConfigSnap = await getDocs(collection(this.firestore, "config", "rateConfig", "rates"));
      allConfigured        = docSnaps.every(docSnap => docSnap && docSnap.exists()) && rateConfigSnap && !rateConfigSnap.empty;
    } catch (e) {
      this.logger.error(e);
      allConfigured = false;
    }

    this.configured.set(allConfigured);
  }
}
