import { AuthGuard, redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { Routes } from "@angular/router";
import { PrivacyComponent } from "./privacy/privacy.component";
import { userInitGuard, verifiedGuard } from "./shared/guards";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["auth/inloggen"]);

export const routes: Routes = [
  {
    path:         "auth",
    loadChildren: () => import("./auth/routes").then(m => m.routes),
    title:        "Authenticatie | Coxhoeve"
  },
  {
    path:         "",
    loadChildren: () => import("./main/routes").then(m => m.routes),
    canActivate:  [AuthGuard, verifiedGuard, userInitGuard],
    data:         {authGuardPipe: redirectUnauthorizedToLogin}
  },
  {path: "privacy", component: PrivacyComponent, title: "Privacy | Coxhoeve"}
];
