<div class="container"><h2>Privacybeleid Stichting Coxhoeve Terschelling</h2>
    <p><a href="https://www.coxhoeve.nl">https://www.coxhoeve.nl</a></p>

    <p>Stichting Coxhoeve Terschelling is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in
       deze privacyverklaring.</p>
    <p>Michiel Cox is de Functionaris Gegevensbescherming van Stichting Coxhoeve Terschelling Hij/zij is te bereiken via
       michielcox&#64;gmail.com</p>

    <h3>Over ons privacybeleid</h3>

    <p>Stichting Coxhoeve Terschelling verwerkt uitsluitend gegevens die wij nodig hebben voor functioneren van de site
       en gaan zorgvuldig om met de informatie die wij over u en
       uw gebruik van onze diensten hebben verzameld. Wij stellen uw gegevens nooit voor commerciële doelstellingen ter
       beschikking aan derden.</p>

    <p>Dit privacybeleid is van toepassing op het gebruik van de website en de daarop ontsloten dienstverlening van
       Stichting Coxhoeve Terschelling. De ingangsdatum voor de geldigheid van deze voorwaarden is 28/10/2019, met het
       publiceren van een nieuwe versie vervalt de geldigheid van alle voorgaande versies. </p>

    <p>Dit privacybeleid beschrijft welke
       gegevens over u door ons worden verzameld, waar deze gegevens voor worden gebruikt en met wie en onder welke
       voorwaarden deze gegevens eventueel met derden kunnen worden gedeeld. Ook leggen wij aan u uit op welke wijze wij
       uw
       gegevens opslaan en hoe wij uw gegevens tegen misbruik beschermen en
       welke rechten u heeft met betrekking tot de door u aan ons verstrekte persoonsgegevens. Als u vragen heeft over
       ons
       privacybeleid kunt u contact opnemen met onze contactpersoon voor privacyzaken, u vindt
       de contactgegevens aan het einde van ons privacybeleid.</p>

    <h3>Over de gegevensverwerking</h3>

    <p>Hieronder kan u lezen op welke wijze wij uw gegevens verwerken, waar wij deze (laten) opslaan, welke
       beveiligingstechnieken wij gebruiken en voor wie de gegevens inzichtelijk zijn.</p>

    <h4>Mailgun Technologies, Inc</h4>

    <p>Wij maken voor ons reguliere zakelijke e-mailverkeer gebruik van de diensten van Mailgun Technologies, Inc. Deze
       partij heeft passende technische en organisatorische maatregelen getroffen om misbruik, verlies en corruptie van
       uw en onze gegevens zoveel mogelijk te voorkomen. Mailgun Technologies, Inc heeft geen toegang tot ons postvak
       en wij behandelen al ons e-mailverkeer vertrouwelijk.</p>

    <h4>Google Analytics</h4>
    <p>Via onze website worden cookies geplaatst van het Amerikaanse bedrijf Google, als deel van de “Analytics”-dienst.
       Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken.
       Deze verwerker is mogelijk verplicht op grond van geldende wet- en regelgeving inzage te geven in deze gegevens.
       Wij hebben Google niet toegestaan de verkregen analytics-informatie te gebruiken voor andere Google diensten.
       Cookies van derde partijen</p>

    <h3>Doel van de gegevensverwerking</h3>

    <h4>Algemeen doel van de verwerking</h4>
    <p>Wij gebruiken uw gegevens uitsluitend ten behoeve van onze dienstverlening op deze website. Dat wil zeggen dat
       het doel van de
       verwerking altijd direct verband houdt met de opdracht die u verstrekt. Wij gebruiken uw gegevens niet voor
       (gerichte) marketing. Uw gegevens worden niet met derden gedeeld, anders dan om aan boekhoudkundige en overige
       administratieve verplichtingen te voldoen.
       Deze derden zijn allemaal tot geheimhouding gehouden op grond van de overeenkomst tussen hen en ons of een eed
       of wettelijke verplichting.</p>

    <h4>Automatisch verzamelde gegevens</h4>
    <p>Gegevens die automatisch worden verzameld door onze website worden verwerkt met het doel onze dienstverlening
       verder te verbeteren. Deze gegevens (bijvoorbeeld uw IP-adres, webbrowser en besturingssysteem) zijn geen
       persoonsgegevens.</p>

    <h4>Medewerking aan fiscaal en strafrechtelijk onderzoek</h4>
    <p>In voorkomende gevallen kan Stichting Coxhoeve Terschelling op grond van een wettelijke verplichting worden
       gehouden tot het delen van uw gegevens in verband met fiscaal of strafrechtelijk onderzoek van overheidswege. In
       een dergelijk geval zijn wij gedwongen uw gegevens te delen, maar wij zullen ons binnen de mogelijkheden die de
       wet ons biedt daartegen verzetten.</p>

    <h3>Bewaartermijnen</h3>
    <p>Wij bewaren uw gegevens zolang u lid bent van de stichting. Dit betekent dat wij uw gebruikers-profiel bewaren
       totdat u aangeeft dat u niet langer van onze diensten gebruik wenst te maken. Als u dit bij ons aangeeft zullen
       wij dit tevens opvatten als een vergeetverzoek. Op grond van toepasselijke administratieve verplichtingen dienen
       wij
       facturen met uw (persoons)gegevens te bewaren, deze gegevens zullen wij dus voor zolang de toepasselijke termijn
       loopt bewaren. Beheerders hebben echter geen toegang meer tot uw cliëntprofiel en documenten die wij naar
       aanleiding van uw opdracht hebben vervaardigd.</p>

    <h3>Uw rechten</h3>
    <p>Op grond van de geldende Nederlandse en Europese wetgeving heeft u als betrokkene bepaalde rechten met
       betrekking tot de persoonsgegevens die door of namens ons worden verwerkt. Wij leggen u hieronder uit welke
       rechten dit zijn en hoe u zich op deze rechten kunt beroepen. In beginsel sturen wij om misbruik te voorkomen
       afschriften en kopieën van uw gegevens enkel naar uw bij ons reeds bekende e-mailadres. In het geval dat u de
       gegevens op een ander e-mailadres of bijvoorbeeld per post wenst te ontvangen, zullen wij u vragen zich te
       legitimeren. Wij houden een administratie bij van afgehandelde verzoeken, in het geval van een vergeetverzoek
       administreren wij geanonimiseerde gegevens. Alle afschriften en kopieën van gegevens ontvangt u in de
       machineleesbare gegevensindeling die wij binnen onze systemen hanteren. U heeft te allen tijde het recht om een
       klacht in te dienen bij de Autoriteit Persoonsgegevens als u vermoedt dat wij uw persoonsgegevens op een
       verkeerde manier gebruiken.</p>

    <h3>Inzagerecht</h3>
    <p>U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw persoon of
       daartoe herleidbaar zijn, in te zien. U kunt een verzoek met die strekking doen aan onze contactpersoon voor
       privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd sturen
       wij u op het bij ons bekende e-mailadres een kopie van alle gegevens met een overzicht van de verwerkers die deze
       gegevens onder zich hebben, onder vermelding van de categorie waaronder wij deze gegevens hebben opgeslagen.
       Rectificatierecht</p>

    <p>U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw persoon of
       daartoe herleidbaar zijn, te laten aanpassen. U kunt een verzoek met die strekking doen aan onze contactpersoon
       voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd
       sturen wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens zijn aangepast.
       Recht op beperking van de verwerking</p>

    <p>U heeft altijd het recht om de gegevens die wij (laten) verwerken die betrekking hebben op uw persoon of daartoe
       herleidbaar zijn, te beperken. U kunt een verzoek met die strekking doen aan onze contactpersoon voor
       privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd sturen
       wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens tot u de beperking opheft niet langer
       worden verwerkt.</p>

    <h3>Recht op overdraagbaarheid</h3>
    <p>U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw persoon of
       daartoe herleidbaar zijn, door een andere partij te laten uitvoeren. U kunt een verzoek met die strekking doen
       aan
       onze contactpersoon voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek
       wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres afschriften of kopieën van alle gegevens over u
       die wij hebben verwerkt of in opdracht van ons door andere verwerkers of derden zijn verwerkt. Naar alle
       waarschijnlijkheid kunnen wij in een dergelijk geval de dienstverlening niet langer voortzetten, omdat de veilige
       koppeling van databestanden dan niet langer kan worden gegarandeerd.</p>

    <h3>Recht van bezwaar en overige rechten</h3>
    <p>U heeft in voorkomende gevallen het recht bezwaar te maken tegen de verwerking van uw persoonsgegevens door
       of in opdracht van Stichting Coxhoeve Terschelling. Als u bezwaar maakt zullen wij onmiddellijk de
       gegevensverwerking staken in afwachting van de afhandeling van uw bezwaar. Is uw bezwaar gegrond dat zullen wij
       afschriften en/of kopieën van gegevens die wij (laten) verwerken aan u ter beschikking stellen en daarna de
       verwerking blijvend staken. U heeft bovendien het recht om niet aan geautomatiseerde individuele besluitvorming
       of
       profiling te worden onderworpen. Wij verwerken uw gegevens niet op zodanige wijze dat dit recht van toepassing
       is.
       Bent u van mening dat dit wel zo is, neem dan contact op met onze contactpersoon voor privacyzaken.</p>


    <h3>Wijzigingen in het privacybeleid</h3>
    <p>Wij behouden te allen tijde het recht ons privacybeleid te wijzigen. Op deze pagina vindt u echter altijd de
       meest
       recente versie. Als het nieuwe privacybeleid gevolgen heeft voor de wijze waarop wij reeds verzamelde gegevens
       met betrekking tot u verwerken, dan brengen wij u daarvan per e-mail op de hoogte.</p>

    <h3>Contactpersoon voor privacyzaken</h3>
    <p>Michiel Cox</p>
    <p>Aalbes 22 <br>3069 NK Rotterdam <br>Nederland <br>T (064) 177-7598 <br>E michielcox&#64;gmail.com</p>


</div>
