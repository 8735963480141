import { computed, effect, inject, Injectable, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
  Auth,
  createUserWithEmailAndPassword,
  getIdTokenResult,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail,
  updatePassword,
  updateProfile,
  user as u,
  User,
  UserCredential
} from "@angular/fire/auth";
import { doc, Firestore, serverTimestamp, updateDoc } from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import * as Sentry from "@sentry/angular-ivy";
import { catchError, take } from "rxjs";
import { LoggingService } from "../shared/services/logging.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  auth: Auth             = inject(Auth);
  firestore: Firestore   = inject(Firestore);
  functions: Functions   = inject(Functions);
  logger: LoggingService = inject(LoggingService);

  readonly user     = toSignal(u(this.auth));
  readonly uid      = computed(() => this.user()?.uid);
  readonly admin    = signal(false);
  readonly loggedIn = computed(() => !!this.user());

  constructor() {
    effect(() => {
      this.setSentryUser(this.user());
      this.checkAdminStatus(this.user());
    }, {
      allowSignalWrites: true
    });
  }

  /**
   * Initialises the auth service
   * Resolves when the auth object is ready (user is loaded)
   */
  public init(): Promise<void> {
    return new Promise(resolve => {
      u(this.auth).pipe(
        take(1),
        catchError((err: any) => {
          this.logger.error("Unable to initialise auth service", err);
          throw new Error("Unable to initialise auth service");
        })
      ).subscribe(() => {
        resolve();
      });
    });
  }

  public async requestTokens() {
    const query          = httpsCallable(this.functions, "auth-getAuthURL");
    const res            = await query({});
    // Redirect to the URL
    window.location.href = res.data as string;
  }

  /**
   * Refreshes the token of the user
   */
  public async refreshToken() {
    const query    = httpsCallable(this.functions, "auth-refreshToken");
    const res: any = await query({uid: this.uid()});
    return res.data.access_token;
  }

  /**
   * Handles the callback from the Google OAuth flow
   * @param code
   */
  public async handleCallback(code: string) {
    if (!this.uid()) throw new Error("User is not signed in");
    const query = httpsCallable(this.functions, "auth-createAndSaveTokens");
    return query({
      code: code,
      uid:  this.uid()
    });
  }

  /**
   * Records the login of the user by updating the date_signedIn property
   * Also updates the displayName, email property in case this has changed (or when it was not set yet)
   */
  public recordLogin() {
    if (this.user()) {
      const ref         = doc(this.firestore, "users", this.uid()!);
      const update: any = {
        date_signedIn: serverTimestamp()
      };
      // if (this.user.displayName) update.displayName = this.user.displayName;
      if (this.user()?.email) update.email = this.user()!.email;
      updateDoc(ref, update);
    }
  }

  /**
   * Logs the user in using Google as an authentication provider
   */
  public async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/calendar.events");
    return signInWithPopup(this.auth, provider)
      .then((user: UserCredential) => {
        // const credential = GoogleAuthProvider.credentialFromResult(user);
        // const token      = credential?.accessToken;
        // console.log(token);
        const ref = doc(this.firestore, "users", user.user.uid);
        updateDoc(ref, {hasCalendarScope: true});
      });
  }

  /**
   * Registers a new user with email and password, and sets the displayName of the auth user
   * @param email
   * @param password
   * @param displayName
   */
  public registerWithEmail(email: string, password: string, displayName: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then(user => {
        updateProfile(user.user, {displayName: displayName});
      });
  }

  /**
   * Signs the user in with email and password
   * @param email
   * @param password
   */
  public signInWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  /**
   * Updates the email of the current user
   * Signs the user in with the old password first, then updates the email
   * @param newEmail
   * @param password
   */
  public async updateEmail(newEmail: string, password: string) {

    // Bail if current user can't be got
    if (!this.auth.currentUser) {
      throw ("not-authenticated");
    }

    // Sign the user in and updateEmail
    await signInWithEmailAndPassword(this.auth, this.auth.currentUser.email as string, password).catch(this.logger.error);
    return updateEmail(this.auth.currentUser, newEmail).catch(this.logger.error);
  }

  /**
   * Updates the password of the current user
   * Signs the user in with the old password first, then updates the password
   * @param newPassword
   * @param currentPassword
   */
  public async updatePassword(newPassword: string, currentPassword: string) {

    // Bail if current user can't be got
    if (!this.auth.currentUser) {
      throw ("not-authenticated");
    }

    // Sign the user in and updatePassword
    await signInWithEmailAndPassword(this.auth, this.auth.currentUser.email as string, currentPassword);
    return updatePassword(this.auth.currentUser, newPassword);
  }

  /**
   * Updates the displayName of the current user in the auth object
   * @param param
   */
  public async updateName(param: {
    displayName: string
  }) {
    if (!this.auth.currentUser) {
      throw ("not-authenticated");
    }
    return updateProfile(this.auth.currentUser, param);
  }

  /**
   * Helper function to check if the user has the admin claim
   * @private
   */
  private async checkAdminStatus(user: User | null | undefined) {
    if (!user) {
      this.admin.set(false);
      return;
    }
    const res = await getIdTokenResult(user);
    this.admin.set(!!res.claims["admin"]);
  }

  /**
   * Sets the user in the Sentry logger
   * @param user
   * @private
   */
  private setSentryUser(user: User | undefined | null) {
    if (user) {
      Sentry.setUser({id: user.uid});
      if (user.email) Sentry.setUser({email: user.email});
      if (user.displayName) Sentry.setUser({username: user.displayName});

    } else {
      Sentry.setUser(null);
    }
  }


}
