import { inject } from "@angular/core";
import { Auth, authState } from "@angular/fire/auth";
import { CanActivateFn, Router } from "@angular/router";
import { from, map, of, switchMap, tap } from "rxjs";
import { LoggingService } from "../services/logging.service";

export const verifiedGuard: CanActivateFn = (route, state) => {
  const auth   = inject(Auth);
  const router = inject(Router);
  const logger = inject(LoggingService);

  // guard to check is user has admin role
  return authState(auth as any).pipe(
    switchMap(credentials => credentials ? from(credentials.getIdTokenResult()) : of(null)),
    map(result => result ? !!result.claims["verified"] : false),
    tap(res => {
      if (!res) {
        logger.warn("User is attempting to access a page without being verified");
        router.navigate(["/auth/voltooid"]);
      }
    })
  );
};
