import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { catchError, of, retry, switchMap, throwError } from "rxjs";
import { LoggingService } from "../services/logging.service";
import { UserService } from "../services/user.service";

export const userInitGuard: CanActivateFn = (route, state) => {

  const userService = inject(UserService);
  const router      = inject(Router);
  const logger      = inject(LoggingService);

  return of(null).pipe(
    switchMap(() => {
      if (userService.user()) {
        console.log("Data ready");
        return of(true);
      } else {
        throw new Error("Data not ready");
      }
    }),
    retry({count: 10, delay: 500}),
    catchError((err) => {
      logger.error("After 10 attempts the user data is still not ready", err);
      router.navigate(["/auth"]);  // Navigate away or handle the error as needed
      return throwError(() => err);
    })
  );

};
