export const environment = {
  firebase:   {
    projectId:         "dorreveldweg",
    appId:             "1:56181968892:web:bd62dab025c2255372ac79",
    apiKey:            "AIzaSyDVD8sQKKvDuQTZT4MIosTZ4VCEWbOgdp8",
    storageBucket:     "dorreveldweg.appspot.com",
    authDomain:        "dorreveldweg.firebaseapp.com",
    databaseURL:       "https://dorreveldweg.firebaseio.com",
    messagingSenderId: "56181968892",
    measurementId:     "G-LTD7XMWFTV"
  },
  production: true
};
