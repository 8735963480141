import { computed, inject, Injectable, Signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { User } from "@angular/fire/auth";
import { deleteField, doc, docData, DocumentReference, Firestore, updateDoc } from "@angular/fire/firestore";
import { catchError, filter, map, Observable, of, switchMap, throwError } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { CoxhoeveUser } from "../models";
import { ConfigService } from "./config.service";
import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: "root"
})
export class UserService {

  firestore     = inject(Firestore);
  authService   = inject(AuthService);
  configService = inject(ConfigService);
  logger        = inject(LoggingService);

  readonly user = toSignal<CoxhoeveUser | null>(this.listenForUserChanges(), {initialValue: null});
  private ref: DocumentReference<any>;


  /**
   * Select property from the user object
   */
  select<T extends keyof CoxhoeveUser>(property: T): Signal<CoxhoeveUser[T] | undefined> {
    return computed(() => this.user()?.[property]);
  }

  /**
   * Updates the user document in the users collection
   * @param data
   */
  async update(data: Partial<CoxhoeveUser>): Promise<any> {
    try {
      return await updateDoc(this.ref, data);
    } catch (err) {
      this.logger.error("Failed to update user", err);
    }
  }

  /**
   * Removes the token from the user document
   */
  public async removeToken() {
    const ref = doc(this.firestore, "users", this.user()!.uid);
    try {
      return await updateDoc(ref, {token: deleteField()});
    } catch (err) {
      this.logger.error("Failed to remove token", err);
    }
  }

  /**
   * Listens for changes in the user document
   * @private
   */
  private listenForUserChanges(): Observable<CoxhoeveUser | null> {
    return toObservable(this.authService.user).pipe(
      filter((user: any) => !!user),
      switchMap((user: User) => {
        if (!user) return of(null);
        this.ref = doc(this.firestore, "users", user.uid);
        return user ? docData(this.ref) : of(null);
      }),
      switchMap((user: CoxhoeveUser) => {
        if (user?.rateId) {
          return this.configService.getRateConfig(user.rateId).pipe(
            map(rateConfig => rateConfig ? ({...user, rateConfig}) : ({...user}))
          );
        } else {
          return of({...user});
        }
      }),
      catchError(err => {
        this.logger.error("Failed to retrieve user", err);
        return throwError(() => "Het ophalen van de gegevens van de gebruiker is mislukt");
      })
    );
  }

}
