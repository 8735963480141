import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoggingService {

  log(message: any, ...optionalParams: any[]): void {
    if (environment.production) {
      Sentry.captureMessage(message, "log");
    } else {
      console.log(message, ...optionalParams);
    }
  }

  debug(message: any, ...optionalParams: any[]): void {
    if (environment.production) {
      Sentry.captureMessage(message, "debug");
    } else {
      console.debug(message, ...optionalParams);
    }
  }

  info(message: any, ...optionalParams: any[]): void {
    if (environment.production) {
      Sentry.captureMessage(message, "info");
    } else {
      console.info(message, ...optionalParams);
    }
  }

  warn(message: any, ...optionalParams: any[]): void {
    if (environment.production) {
      Sentry.captureMessage(message, "warning");
    } else {
      console.warn(message, ...optionalParams);
    }
  }

  error(message: any, ...optionalParams: any[]): void {
    if (environment.production) {
      Sentry.captureException(optionalParams);
    } else {
      console.error(message, ...optionalParams);
    }
  }
}
