import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { AuthService } from "./auth/auth.service";

@Component({
  selector:   "app-root",
  template:   "<router-outlet></router-outlet>",
  styles:     [""],
  standalone: true,
  imports:    [RouterOutlet]
})
export class AppComponent {


  constructor(private authService: AuthService) {
    this.authService.recordLogin();
    this.setColorScheme();
    this.observeColorScheme();
  }


  private setColorScheme() {
    // Check if the user has set a preference for a dark or light color scheme
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.documentElement.setAttribute("data-theme", "dark")
    }
  }

  private observeColorScheme() {
    // Observe the prefers-color-scheme media query and update the data-theme attribute accordingly
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", event => {
      const newColorScheme = event.matches ? "dark" : "light";
      if (newColorScheme === "dark") {
        document.documentElement.setAttribute("data-theme", "dark")
      } else {
        document.documentElement.setAttribute("data-theme", "light")
      }
    });
  }


}
